import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ValidateInputs } from "../../helper/helper";

const UpdateFaq = () => {
  const [faq, setFaq] = useState({});
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });
  const [errors, setErrors] = useState({});
  const { faqId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFaqDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/faq/view`,
          {
            params: { id: faqId },
          }
        );
        setFaq(response?.data);
        setFormData({
          question: response?.data?.faq?.question,
          answer: response?.data?.faq?.answer,
        });
      } catch (error) {
        console.error("Error fetching faq details:", error);
      }
    };
    fetchFaqDetails();
  }, [faqId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const updatedInputs = ValidateInputs(name, value, errors);
    setErrors(updatedInputs);
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.question.trim()) {
      newErrors.question = "Question is required";
    } else if (formData.question.length > 100) {
      newErrors.question = "Question not more then 100 words";
    } else if (!formData.answer.trim()) {
      newErrors.answer = "Answer is required";
    } else if (formData.answer.length > 1000) {
      newErrors.answer = "Answer not more then 1000 words";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/faq/update`, {
        id: faqId,
        ...formData,
      });
      toast.success("Faq details updated successfully!");
      navigate("/faq");
    } catch (error) {
      console.error("Error updating faq details:", error);
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Faq Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleFormSubmit}>
                    <div className="card-body">
                      {faq && faq.faq && (
                        <>
                          <div className="form-group">
                            <label htmlFor="question">Question</label>
                            <input
                              type="text"
                              name="question"
                              className={`form-control ${
                                errors.question ? "is-invalid" : ""
                              }`}
                              placeholder="Enter question"
                              value={formData.question}
                              onChange={handleInputChange}
                              maxLength={200}
                            />
                            {errors.question && (
                              <div className="invalid-feedback">
                                {errors.question}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="answer">Answer</label>
                            <textarea
                              type="text"
                              name="answer"
                              className={`form-control ${
                                errors.answer ? "is-invalid" : ""
                              }`}
                              placeholder="Enter answer"
                              value={formData.answer}
                              onChange={handleInputChange}
                              style={{ height: "100px" }}
                              maxLength={500}
                            />
                            {errors.answer && (
                              <div className="invalid-feedback">
                                {errors.answer}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateFaq;
