import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import { IoCopyOutline } from "react-icons/io5";
import { toast } from "react-toastify";

const WalletUserView = () => {
  const [user, setUser] = useState({});
  const { userId } = useParams();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_EXTENSION_API_URL}/users/view`,
          {
            params: { id: userId },
          }
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, [userId]);

  const copyToClipboard = (address) => {
    if (address) {
      navigator?.clipboard?.writeText(address)
        .then(() => {
          toast.success("Address copied to clipboard!");
        })
        .catch((error) => {
          toast.error("Failed to copy address.");
          console.error("Error copying to clipboard:", error);
        });
    } else {
      toast.warning("No address available to copy.");
    }
  }

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">User Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body view-page">

                    {user && user?.user && (
                      <>
                        <div className="user-info">
                          <strong>Name:</strong> {user?.user?.name}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Email:</strong> {user?.user?.email}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Public Address:</strong> {user?.user?.evmWallet?.address}
                          <button
                            onClick={() => copyToClipboard(user?.user?.evmWallet?.address)}
                            style={{ marginLeft: "2rem", border: "none", background: "transparent" }}
                          >
                            <IoCopyOutline />
                          </button>
                        </div>

                        <br />
                        <div className="user-info">
                          <strong>QR:</strong>
                          <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
                            <QRCode
                              size={256}
                              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                              value={user?.user?.evmWallet?.address}
                              viewBox={`0 0 256 256`}
                            />
                          </div>
                        </div>
                        <br />

                        {/* <div className="user-info">
                          <strong>IsActive:</strong> {user.user.isActive ? "True" : "False"}
                        </div> */}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletUserView;