import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { IoCopyOutline } from "react-icons/io5";
import QRCode from "react-qr-code";
import axios from "axios";
import { useParams } from "react-router-dom";
import ViewAppTransaction from "./ViewAppTransaction";
import { copyToClipboard } from "../../helper/helper";

const ViewApp = () => {
  const { userId } = useParams();
  const [appDetails, setAppDetails] = useState("");
  const [depositAddress, setDepositAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchAppsDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/apps/view`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            id: userId,
            // pageNo: currentPage,
            // limitVal: 10,
            // search: searchQuery,
          },
        }
      );
      //   setApps(response?.data?.data || []);
      //   setTotalPages(response?.data?.data?.totalPages);
      if (response?.data) {
        setAppDetails(response?.data?.app);
        setDepositAddress(response?.data?.app?.EVMWalletMnemonic?.address);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    fetchAppsDetails();
  }, [userId, depositAddress]);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 d-flex justify-content-between">
              <div className="col-sm-6 mb-2">
                <h1 className="m-0">App Wallet Details</h1>
              </div>
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body view-page">
                    <>
                      <div className="user-info">
                        <strong>API KEYS:</strong>
                        <span style={{ marginLeft: "2rem" }}>
                          ***************************
                        </span>
                        <button
                          onClick={() => copyToClipboard(appDetails?.apiKey)}
                          style={{
                            marginLeft: "2rem",
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          <IoCopyOutline />
                        </button>
                      </div>
                      <div className="user-info">
                        <strong>SECRET KEYS:</strong>
                        <span style={{ marginLeft: "2rem" }}>
                          ***************************
                        </span>
                        <button
                          onClick={() => copyToClipboard(appDetails?.secretKey)}
                          style={{
                            marginLeft: "2rem",
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          <IoCopyOutline />
                        </button>
                      </div>
                      <div className="user-info">
                        <strong>Wallet Address:</strong>
                        <span style={{ marginLeft: "1rem" }}>
                          {depositAddress}
                        </span>
                        <button
                          onClick={() => copyToClipboard(depositAddress)}
                          style={{
                            marginLeft: "2rem",
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          <IoCopyOutline />
                        </button>
                      </div>
                      <br />
                      <div className="user-info">
                        <strong>QR:</strong>
                        <div
                          style={{
                            height: "auto",
                            margin: "0 auto",
                            maxWidth: 150,
                            width: "100%",
                          }}
                        >
                          <QRCode
                            size={256}
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                              width: "100%",
                            }}
                            value={depositAddress}
                            viewBox={`0 0 256 256`}
                          />
                        </div>
                      </div>
                      <br />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ViewAppTransaction address={depositAddress} />
        </div>
      </div>
    </div>
  );
};

export default ViewApp;
