import { Contract, ethers } from "ethers";
import { tokenABI } from "./ABI/tokenABI";

export const NATIVE = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";

export function getNetwork(chainId) {
  switch (chainId) {
    case 11155111:
      return {
        network: "Ethereum",
        symbol: "ETH",
        rpc: "https://eth-sepolia.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA",
      };
    case 80002:
      return {
        network: "Polygon",
        symbol: "MATIC",
        rpc: "https://polygon-amoy.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA",
      };
    case 97:
      return {
        network: "Binance Smart Chain",
        symbol: "BNB",
        rpc: "https://data-seed-prebsc-1-s1.bnbchain.org:8545",
      };
    case 43113:
      return {
        network: "Avalanche",
        symbol: "AVAX",
        rpc: "https://avax-fuji.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA",
      };
    default:
      throw new Error("Unsupported chainId");
  }
}

export async function getNativeTransferTxFee(chainId, transactionDetails) {
  try {
    const Network = getNetwork(chainId);
    console.log("get RPC URL: ", Network);
    const provider = new ethers.providers.JsonRpcProvider(Network.rpc);

    // Get current gas price
    const gasPrice = await provider.getGasPrice();
    console.log("Current gas price (wei): ", gasPrice.toString());
    const gasP = gasPrice.toString();

    // Estimate gas limit for the provided transaction details
    const gasLimit = await provider.estimateGas(transactionDetails);
    console.log("Estimated gas limit: ", gasLimit.toString());

    // Calculate the transaction fee
    const txFee = gasPrice.mul(gasLimit);
    const formattedTxFee = ethers.utils.formatEther(txFee);
    console.log("Estimated transaction fee (ETH): ", formattedTxFee);

    return gasP;
  } catch (e) {
    console.error("Error in transaction fee estimation:", e);
  }
}

// Function to get transaction fee for a smart contract function call
export async function getERC20TransferTxFee(
  chainId,
  contractAddress,
  receiverAddress,
  amount
) {
  try {
    console.log(
      "Chain ID, Contract Address, Receiver Address, Amount: ",
      chainId,
      contractAddress,
      receiverAddress,
      amount
    );
    const Network = getNetwork(chainId);
    // const provider = new ethers.providers.JsonRpcProvider(Network.rpc);

    // Create contract instance
    // const contract = new Contract(contractAddress, tokenABI, signer);

    // console.log("contract : ", contract);

    // // Estimate gas limit for the transfer function
    // const gasLimit = await contract.estimateGas.transfer(
    //   contractAddress, //receiverAddress,
    //   "1000000000000000000",
    //   {
    //     gasPrice: 3000000,
    //   }
    // );
    // console.log("Estimated gas limit: ", gasLimit.toString());

    // // Get current gas price
    // const gasPrice = await provider.getGasPrice();
    // console.log("Current gas price (wei): ", gasPrice.toString());

    // // Calculate the transaction fee
    // const txFee = gasPrice.mul(gasLimit);
    // const formattedTxFee = ethers.utils.formatEther(txFee);
    // console.log("Estimated transaction fee (ETH): ", formattedTxFee);

    // return formattedTxFee;
  } catch (e) {
    console.error("Error in transaction fee estimation:", e);
  }
}

export async function isValidAddress(address) {
  const result = await ethers.utils.isAddress(address);
  console.log("result: ", result, address);

  return result;
}
