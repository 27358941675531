export const labels = ["Jan", "FEB", "MAR", "APR", "MAY", "JUN", "JUL"];

function getRndInteger(min, max, count) {
    let array = [];
    for (let i = 0; i < count; i++) {

        array.push(Math.floor(Math.random() * (max - min + 1)) + min);
    }
    return array;
}

export const datasets1 = [
    {
        label: 'Deposits',
        data: [10, 20, 40, 60, 18],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
        label: 'Withdrawals',
        data: [20, 40, 20, 10, 80],
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
    }
];

export const datasets2 = [
    {
        label: 'Dataset 1',
        data: [20, 40, 20, 10, 80],
    }
]

export const datasets3 = [
    {
        label: 'Admin Revenue',
        data: getRndInteger(1, 100, 5),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        stack: 'combined',
        type: 'bar'
    },
    {
        label: 'Admin Funds',
        data: getRndInteger(1, 100, 5),
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        stack: 'combined'
    }
]

export const datasets4 = [
    {
        label: 'ETH',
        data: getRndInteger(1, 100, 7),
        borderColor: 'rgb(54,162,235)',
        backgroundColor: 'white',
    },

    {
        label: 'BNB',
        data: getRndInteger(1, 100, 7),
        borderColor: 'rgb(246,99,132)',
        backgroundColor: 'white',
    },

    {
        label: 'USDT',
        data: getRndInteger(1, 100, 7),
        borderColor: 'rgb(248,159,64)',
        backgroundColor: 'white',
    },
    {
        label: 'USDC',
        data: getRndInteger(1, 100, 7),
        borderColor: 'rgb(251,205,86)',
        backgroundColor: 'white',
    },
    {
        label: 'MATIC',
        data: getRndInteger(1, 100, 7),
        borderColor: 'rgb(75,192,192)',
        backgroundColor: 'white',
    },
]

export const timeFilter = () => {
    const data = {
        name: "abc",
        date: new Date().getTime(),  // Current time in milliseconds
    };
    
    const currentTime = new Date().getTime();  // Current time in milliseconds
    
    const millisecondsInADay = 24 * 60 * 60 * 1000;  // Milliseconds in a day
    const millisecondsOf30Days = millisecondsInADay * 30;
    const millisecondsOf90Days = millisecondsInADay * 90;
    const millisecondsOfYearly = millisecondsInADay * 365;
    const millisecondsOf30DaysInterval = currentTime - millisecondsOf30Days;  // 7 days interval in milliseconds
    const millisecondsOf90DaysInterval = currentTime - millisecondsOf90Days;  // 7 days interval in milliseconds
    const millisecondsOfYearlyInterval = currentTime - millisecondsOfYearly;  // 7 days interval in milliseconds
}