import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ValidateInputs } from "../../helper/helper";

const WalletUserEdit = () => {
  const [user, setUser] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_EXTENSION_API_URL}/users/view`,
          {
            params: { id: userId },
          }
        );
        setUser(response?.data);
        setFormData({
          name: response?.data?.user?.name,
          email: response?.data?.user?.email,
        });
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const updatedInputs = ValidateInputs(name, value, errors);
    setErrors(updatedInputs);
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      await axios.put(
        `${process.env.REACT_APP_EXTENSION_API_URL}/users/update-user-profile`,
        { id: userId, ...formData }
      );
      toast.success("User details updated successfully!");
      navigate("/users");
    } catch (error) {
      console.error("Error updating user details:", error);
      alert("Failed to update user details.");
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">User Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleFormSubmit}>
                    <div className="card-body">
                      {user && user?.user && (
                        <>
                          <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                              type="text"
                              name="name"
                              className={`form-control ${
                                errors.name ? "is-invalid" : ""
                              }`}
                              placeholder="Enter name"
                              value={formData?.name}
                              onChange={handleInputChange}
                              maxLength={50}
                            />
                            {errors.name && (
                              <div className="invalid-feedback">
                                {errors.name}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                              type="text"
                              name="email"
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              placeholder="Enter email"
                              value={formData.email}
                              onChange={handleInputChange}
                              readOnly
                            />
                            {/* {errors.email && <div className="invalid-feedback">{errors.email}</div>} */}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletUserEdit;
