import { toast } from "react-toastify";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import { isValidAddress } from "./evm.helper";

export const formatNumber = (input, decimalPlace) => {
  if (!(input && decimalPlace)) {
    return 0;
  }
  let str = input.toString();
  let dotIndex = str.indexOf(".");
  if (dotIndex === -1) {
    return str;
  }
  let preDot = str.substring(0, dotIndex + 1);
  let postDot = str.substring(dotIndex + 1, dotIndex + decimalPlace + 1);

  return preDot + postDot;
};

export const trimAddress = (address, firstChar, lastChar) => {
  if (!address || typeof address !== "string") {
    return "";
  }

  if (address.length <= firstChar + lastChar) {
    return address;
  } else {
    return address.slice(0, firstChar) + "..." + address.slice(-lastChar);
  }
};

export const formatDate = (dateString) => {
  // Convert the date string to a Date object
  const date = new Date(dateString);

  // Array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get day, month, year, hours, and minutes
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format the date in "DD-Mon-YYYY hh:mmAM/PM" format
  const formattedDate = `${day}-${monthNames[monthIndex]}-${year} ${
    hours % 12 || 12
  }:${minutes < 10 ? "0" + minutes : minutes}${hours >= 12 ? " PM" : " AM"}`;

  return formattedDate;
};

export function getNetwork(chainId) {
  switch (chainId) {
    case 11155111:
      return {
        network: "Ethereum",
        symbol: "ETH",
        rpc: "https://eth-sepolia.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA",
      };
    case 80002:
      return {
        network: "Polygon",
        symbol: "MATIC",
        rpc: "https://polygon-amoy.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA",
      };
    case 97:
      return {
        network: "Binance Smart Chain",
        symbol: "BNB",
        rpc: "https://data-seed-prebsc-1-s1.bnbchain.org:8545",
      };
    case 43113:
      return {
        network: "Avalanche",
        symbol: "AVAX",
        rpc: "https://avax-fuji.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA",
      };
    default:
      throw new Error("Unsupported chainId");
  }
}

export const copyToClipboard = (address) => {
  if (address) {
    navigator?.clipboard
      ?.writeText(address)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((error) => {
        toast.error("Failed to copy address.");
        console.error("Error copying to clipboard:", error);
      });
  } else {
    toast.warning("No address available to copy.");
  }
};

export const exportToPDF = (data) => {
  const doc = new jsPDF();
  autoTable(doc, {
    head: [
      [
        "S.N.",
        "Name",
        "Email",
        "PlatformName",
        "PlatformCategory",
        "ContactNumber",
        "Location",
        "Description",
      ],
    ],
    body: data.map((user, index) => [
      index + 1,
      user?.name || "",
      user?.email || "",
      user?.platformName || "",
      user?.platformCategory || "",
      user?.contactNumber || "",
      user?.location || "",
      user?.description || "",
    ]),
  });
  doc.save("users.pdf");
};

export const exportToExcel = (data) => {
  const worksheet = XLSX.utils.json_to_sheet(
    data.map((user, index) => ({
      S_N: index + 1,
      Name: user?.name || "",
      Email: user?.email || "",
      PlatformName: user?.platformName || "",
      PlatformCategory: user?.platformCategory || "",
      ContactNumber: user?.contactNumber || "",
      Location: user?.location || "",
      Description: user?.description || "",
    }))
  );
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
  XLSX.writeFile(workbook, "users.xlsx");
};

export const ValidateInputs = async (name, value, errors) => {
  const newErrors = { ...errors };

  switch (name) {
    case "name":
      newErrors.name = !value.trim() ? "Name is required" : "";
      break;
    case "email":
      newErrors.email = !value.trim()
        ? "Email is required"
        : !/\S+@\S+\.\S+/.test(value)
        ? "Email is invalid"
        : "";
      break;
    case "contactNumber":
      newErrors.contactNumber = !value
        ? "Contact number is required"
        : !/^\d{10}$/.test(value)
        ? "Contact number is invalid"
        : "";
      break;
    case "platformName":
      newErrors.platformName = !value.trim() ? "Platform name is required" : "";
      break;
    case "platformCategory":
      newErrors.platformCategory = !value.trim()
        ? "Platform category is required"
        : "";
      break;
    case "location":
      newErrors.location = !value.trim() ? "Location is required" : "";
      break;
    case "description":
      newErrors.description = !value.trim() ? "Description is required" : "";
      break;
    case "uniqueID":
      newErrors.uniqueID = !value ? "Password is required" : "";
      break;
    case "question":
      newErrors.question = !value.trim() ? "Question is required" : "";
      break;
    case "answer":
      newErrors.answer = !value.trim() ? "Answer is required" : "";
      break;

    case "platformFee":
      newErrors.platformFee = !value.trim()
        ? "Platform fee is required"
        : isNaN(value) || Number(value) < 0 || Number(value) > 50
        ? "Platform Fee must be a positive number between 0 and 50"
        : "";
      break;

    case "adminWallet":
      const isValidEVMAddress = await isValidAddress(value);

      newErrors.adminWallet = !value.trim()
        ? "Admin wallet is required"
        : !isValidEVMAddress
        ? "Invalid admin wallet address"
        : "";
      break;

    default:
      break;
  }

  return newErrors;
};
