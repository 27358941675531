import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const ViewInquiry = () => {
  const [user, setUser] = useState({});
  const { userId } = useParams();

  // Okay

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/inquiry/view`,
          {
            params: { id: userId },
          }
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, [userId]);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Inquiry Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body view-page">
                    {user && user?.user && (
                      <>
                        <div className="user-info">
                          <strong>Name:</strong> {user?.user?.name}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Email:</strong> {user?.user?.email}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Platform Name:</strong>{" "}
                          {user?.user?.platformName}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Platform Category:</strong>{" "}
                          {user?.user?.platformCategory}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Contact Number:</strong>{" "}
                          {user?.user?.contactNumber}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Location:</strong> {user?.user?.location}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Description:</strong>{" "}
                          {user?.user?.description}
                        </div>
                        <br />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewInquiry;
