import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

const Faq = () => {
  const [faqs, setFaqs] = useState([]);

  const fetchFaqs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/faq/list`
      );
      console.log("response", response);
      setFaqs(response?.data?.data);
    } catch (error) {
      console.error("Error fetching faqs:", error);
    }
  };

  const deleteFaq = async (id) => {
    try {
      Swal.fire({
        title: "Delete FAQ!",
        text: "Are you sure want to delete FAQ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/faq/delete?id=${id}`
          );
          setFaqs((prevFaqs) => prevFaqs.filter((faq) => faq._id !== id));
          toast.success("Deleted successfully!");
        }
      });
    } catch (error) {
      console.error("Error deleting faq:", error);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div
              className="row mb-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="col-sm-3">
                <h1 className="m-0">Faq Management</h1>
              </div>
              <div className="col-sm-1">
                <Link to="/faq-add">
                  <button className="btn btn-primary">
                    <FontAwesomeIcon icon={faPlus} />
                    {" Add More"}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Question</th>
                          <th>Answer</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {faqs.map((data, index) => (
                          <tr key={index}>
                            <td>{data?.question}</td>
                            <td>{truncateText(data?.answer, 100)}</td>
                            <td>
                              <Link
                                to={`/faq-update/${data._id}`}
                                className="btn btn-link merch-edit"
                              >
                                <i className="fas fa-edit"></i>
                              </Link>
                              <button
                                className="btn btn-link text-danger"
                                onClick={() => deleteFaq(data._id)}
                              >
                                <i className="fas fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
