import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const UpdateTestimonial = () => {
  const [page, setPage] = useState({});
  const [pageName, setPageName] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    subTitle: "",
    heading1: "",
    description1: "",
    heading2: "",
    description2: "",
    heading3: "",
    description3: "",
  });
  const [errors, setErrors] = useState({});
  const { pageId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPageDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/testimonial/view`,
          {
            params: { id: pageId },
          }
        );
        setPage(response?.data);
        setPageName(response?.data?.page?.name);

        setFormData({
          title: response?.data?.page?.title,
          subTitle: response?.data?.page?.subTitle,
          heading1: response?.data?.page?.heading1 || "",
          description1: response?.data?.page?.description1 || "",
          heading2: response?.data?.page?.heading2 || "",
          description2: response?.data?.page?.description2 || "",
          heading3: response?.data?.page?.heading3 || "",
          description3: response?.data?.page?.description3 || "",
        });
      } catch (error) {
        console.error("Error fetching page details:", error);
      }
    };
    fetchPageDetails();
  }, [pageId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditorChange1 = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description1: data,
    }));
  };

  const handleEditorChange2 = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description2: data,
    }));
  };

  const handleEditorChange3 = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description3: data,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData?.title.trim()) {
      newErrors.title = "Title is required";
    } else if (formData.heading1 && !formData?.heading1.trim()) {
      newErrors.heading1 = "Heading1 is required";
    } else if (formData.description1 && !formData?.description1.trim()) {
      newErrors.description1 = "Description1 is required";
    } else if (
      formData.description1 &&
      formData?.description1?.length > 10000
    ) {
      newErrors.description1 =
        "Description must be shorter than or equal to 1000 characters";
    } else if (formData.heading2 && !formData?.heading2.trim()) {
      newErrors.heading2 = "Heading2 is required";
    } else if (formData.description2 && !formData?.description2.trim()) {
      newErrors.description2 = "Description2 is required";
    } else if (
      formData.description2 &&
      formData?.description2?.length > 10000
    ) {
      newErrors.description2 =
        "Description must be shorter than or equal to 1000 characters";
    } else if (formData.heading3 && !formData?.heading3.trim()) {
      newErrors.heading3 = "Heading3 is required";
    } else if (formData.description3 && !formData?.description3.trim()) {
      newErrors.description3 = "Description3 is required";
    } else if (
      formData.description3 &&
      formData?.description3?.length > 10000
    ) {
      newErrors.description3 =
        "Description must be shorter than or equal to 1000 characters";
    } else if (formData.subTitle && !formData?.subTitle.trim()) {
      newErrors.subTitle = "subTitle is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/testimonial/update`, {
        id: pageId,
        ...formData,
      });
      toast.success("Page details updated successfully!");
      navigate("/content-management");
    } catch (error) {
      console.error("Error updating content details:", error);
      alert("Failed to update content details.");
    }
  };

  class MyUploadAdapter {
    constructor(loader) {
      this.loader = loader;
    }
    upload() {
      return this.loader.file.then(
        (file) =>
          new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("image", file);
            console.log("formData", formData);

            axios
              .post(
                `${process.env.REACT_APP_API_URL}/pages/file-upload`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((response) => {
                resolve({
                  default: response?.data?.url,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
      );
    }

    abort() {}
  }

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader);
    };
  }

  console.log("errors", errors);
  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">{pageName}</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleFormSubmit}>
                    <div className="card-body">
                      {page && page.page && (
                        <>
                          <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input
                              type="text"
                              name="title"
                              className={`form-control ${
                                errors.title ? "is-invalid" : ""
                              }`}
                              placeholder="Enter title"
                              value={formData.title}
                              onChange={handleInputChange}
                            />
                            {errors.title && (
                              <div className="invalid-feedback">
                                {errors.title}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="subTitle">Sub Title</label>
                            <input
                              type="text"
                              name="subTitle"
                              className={`form-control ${
                                errors.subTitle ? "is-invalid" : ""
                              }`}
                              placeholder="Enter subTitle"
                              value={formData.subTitle}
                              onChange={handleInputChange}
                            />
                            {errors.subTitle && (
                              <div className="invalid-feedback">
                                {errors.subTitle}
                              </div>
                            )}
                          </div>

                          {formData && (
                            <div className="form-group">
                              <label htmlFor="heading">Heading1</label>
                              <input
                                type="text"
                                name="heading1"
                                className={`form-control ${
                                  errors.heading1 ? "is-invalid" : ""
                                }`}
                                placeholder="Enter heading1"
                                value={formData.heading1}
                                onChange={handleInputChange}
                              />
                              {errors.heading1 && (
                                <div className="invalid-feedback">
                                  {errors.heading1}
                                </div>
                              )}
                            </div>
                          )}

                          {formData && (
                            <div className="form-group">
                              <label htmlFor="description1">Description1</label>

                              <CKEditor
                                editor={ClassicEditor}
                                data={formData.description1}
                                onChange={handleEditorChange1}
                                config={{
                                  extraPlugins: [MyCustomUploadAdapterPlugin],
                                }}
                              />
                              {errors.description1 && (
                                <div className="invalid-feedback d-block">
                                  {errors.description1}
                                </div>
                              )}
                            </div>
                          )}

                          {formData && (
                            <div className="form-group">
                              <label htmlFor="heading2">Heading2</label>
                              <input
                                type="text"
                                name="heading2"
                                className={`form-control ${
                                  errors.heading2 ? "is-invalid" : ""
                                }`}
                                placeholder="Enter heading2"
                                value={formData.heading2}
                                onChange={handleInputChange}
                              />
                              {errors.heading2 && (
                                <div className="invalid-feedback">
                                  {errors.heading2}
                                </div>
                              )}
                            </div>
                          )}
                          {formData && (
                            <div className="form-group">
                              <label htmlFor="description2">Description2</label>

                              <CKEditor
                                editor={ClassicEditor}
                                data={formData.description2}
                                onChange={handleEditorChange2}
                                config={{
                                  extraPlugins: [MyCustomUploadAdapterPlugin],
                                }}
                              />
                              {errors.description2 && (
                                <div className="invalid-feedback d-block">
                                  {errors.description2}
                                </div>
                              )}
                            </div>
                          )}

                          {formData && (
                            <div className="form-group">
                              <label htmlFor="heading3">Heading3</label>
                              <input
                                type="text"
                                name="heading3"
                                className={`form-control ${
                                  errors.heading3 ? "is-invalid" : ""
                                }`}
                                placeholder="Enter heading3"
                                value={formData.heading3}
                                onChange={handleInputChange}
                              />
                              {errors.heading3 && (
                                <div className="invalid-feedback">
                                  {errors.heading3}
                                </div>
                              )}
                            </div>
                          )}
                          {formData && (
                            <div className="form-group">
                              <label htmlFor="description3">Description3</label>

                              <CKEditor
                                editor={ClassicEditor}
                                data={formData.description3}
                                onChange={handleEditorChange3}
                                config={{
                                  extraPlugins: [MyCustomUploadAdapterPlugin],
                                }}
                              />
                              {errors.description3 && (
                                <div className="invalid-feedback d-block">
                                  {errors.description3}
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateTestimonial;
