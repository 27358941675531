import { io } from "socket.io-client";

const URL = process.env.REACT_APP_EXTENSION_API_URL;

// const io = new Server({
//   cors: {
//     origin: "*",
//   },
// });

// // io.listen(process.env.PORT);
// io.listen(3007);

export const socket = io(URL);
// export const socket = io(URL, {
//   autoConnect: false,
// });
