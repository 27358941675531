import React from "react";

const Footer = () => {
  return (
    <div>
      <footer className="main-footer">
        <strong>Copyright© 2024 All Right Reserved by Coinpera</strong>
      </footer>

      <aside className="control-sidebar control-sidebar-dark"></aside>
    </div>
  );
};

export default Footer;
