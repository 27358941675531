import React, { useState, useEffect } from "react";
import Chart from "chart.js/auto";
import axios from "axios";
import "./Report.css";
import { datasets1, datasets2, datasets3, datasets4, labels, timeFilter } from "./dummyData";

const Reports = () => {
  const [hoverInfo, setHoverInfo] = useState(null);
  const [charts, setCharts] = useState();

  const handleMouseEnter = (info) => {
    setHoverInfo(info);
  };

  const handleMouseLeave = () => {
    setHoverInfo(null);
  };

  const fetchMerchants = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/payment-link/count`
  /*       {
          params: {
            code : 'USDT.MATIC' 
          }
        } */
      );
      
      setCharts(response?.data?.data);
      // console.log(response?.data?.data)
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchMerchants();
  }, [])

  useEffect(() => {

    const data = {
      labels: labels,
      datasets: datasets4
    };

    const data2 = {
      labels: labels,
      datasets: datasets3
    };

    const data3 = {
      labels: ['ETH', 'BNB', 'USDT', 'USDC', 'MATIC'],
      datasets: datasets2
    };

    const data4 = {
      labels: labels,
      datasets: datasets1
    };

    const config = {
      type: 'line',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Payment Links'
          }
        }
      },
    };

    const config2 = {
      type: 'line',
      data: data2,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Revenue (%)'
          }
        },
        scales: {
          y: {
            stacked: true
          }
        }
      },
    };

    const config3 = {
      type: 'doughnut',
      data: data3,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Crypto Margins'
          }
        }
      },
    };

    const config4 = {
      type: 'bar',
      data: data4,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Top 10 Merchant Deposits/Withdrawals'
          }
        }
      },
    };

    // const actions3 = [
    //   {
    //     name: 'Randomize',
    //     handler(chart) {
    //       chart.data.datasets.forEach(dataset => {
    //         dataset.data = Utils.numbers({count: chart.data.labels.length, min: 0, max: 100});
    //       });
    //       chart.update();
    //     }
    //   },
    //   {
    //     name: 'Add Dataset',
    //     handler(chart) {
    //       const data = chart.data;
    //       const newDataset = {
    //         label: 'Dataset ' + (data.datasets.length + 1),
    //         backgroundColor: [],
    //         data: [],
    //       };

    //       for (let i = 0; i < data.labels.length; i++) {
    //         newDataset.data.push(Utils.numbers({count: 1, min: 0, max: 100}));

    //         const colorIndex = i % Object.keys(Utils.CHART_COLORS).length;
    //         newDataset.backgroundColor.push(Object.values(Utils.CHART_COLORS)[colorIndex]);
    //       }

    //       chart.data.datasets.push(newDataset);
    //       chart.update();
    //     }
    //   },
    //   {
    //     name: 'Add Data',
    //     handler(chart) {
    //       const data = chart.data;
    //       if (data.datasets.length > 0) {
    //         data.labels.push('data #' + (data.labels.length + 1));

    //         for (let index = 0; index < data.datasets.length; ++index) {
    //           data.datasets[index].data.push(Utils.rand(0, 100));
    //         }

    //         chart.update();
    //       }
    //     }
    //   },
    //   {
    //     name: 'Hide(0)',
    //     handler(chart) {
    //       chart.hide(0);
    //     }
    //   },
    //   {
    //     name: 'Show(0)',
    //     handler(chart) {
    //       chart.show(0);
    //     }
    //   },
    //   {
    //     name: 'Hide (0, 1)',
    //     handler(chart) {
    //       chart.hide(0, 1);
    //     }
    //   },
    //   {
    //     name: 'Show (0, 1)',
    //     handler(chart) {
    //       chart.show(0, 1);
    //     }
    //   },
    //   {
    //     name: 'Remove Dataset',
    //     handler(chart) {
    //       chart.data.datasets.pop();
    //       chart.update();
    //     }
    //   },
    //   {
    //     name: 'Remove Data',
    //     handler(chart) {
    //       chart.data.labels.splice(-1, 1); // remove the label first

    //       chart.data.datasets.forEach(dataset => {
    //         dataset.data.pop();
    //       });

    //       chart.update();
    //     }
    //   }
    // ];

    const ctx = document.getElementById("lineChart");
    const ctx2 = document.getElementById("stacked-bar");
    const ctx3 = document.getElementById("doughnut");
    const ctx4 = document.getElementById("vertical-bar");

    new Chart(ctx, config);
    new Chart(ctx2, config2)
    new Chart(ctx3, config3)
    new Chart(ctx4, config4)

  }, []);

  return (
    <div>
      <div className="content-header">
        <section className="content">
          <div className="card">
            <div className="card-body">
              <div class="card-footer box-container">
                <div class="row">
                  <div class="col-sm-3 col-6">
                    <div class="description-block border-right">
                      <h5 class="description-header">25</h5>
                      <span class="description-text">Total Wallet Users {charts}</span>
                    </div>

                  </div>

                  <div class="col-sm-3 col-6">
                    <div class="description-block border-right">
                      <h5 class="description-header">20</h5>
                      <span class="description-text">Total Merchants</span>
                    </div>

                  </div>

                  <div class="col-sm-3 col-6">
                    <div class="description-block border-right">
                      <h5 class="description-header">12</h5>
                      <span class="description-text">Active Payment Links</span>
                    </div>

                  </div>

                  <div class="col-sm-3 col-6">
                    <div class="description-block">
                      <h5 class="description-header">10</h5>
                      <span class="description-text">Active Merchant Apps</span>
                    </div>

                  </div>
                </div>

              </div>
              <div className="container-fluid">
                <div className="row mb-2 report-container">
                  <div></div>
                  <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-secondary">
                      <input type="radio" name="options" id="option3" autocomplete="off" /> MONTHLY
                    </label>
                    <label class="btn btn-secondary">
                      <input type="radio" name="options" id="option3" autocomplete="off" /> QUARTERLY
                    </label>
                    <label class="btn btn-secondary">
                      <input type="radio" name="options" id="option3" autocomplete="off" /> YEARLY
                    </label>
                  </div>
                </div>
              </div>
              <div className="container-fluid main-diagram">
                <div className="row mb-2 diagram-parent">
                  <div className="col-sm-6 chart-parent line-chart">
                    <canvas id="lineChart"></canvas>
                  </div>
                  <div className="col-sm-6 chart-parent stacked-chart">
                    <canvas id="stacked-bar"></canvas>
                  </div>
                </div>
                <div className="row mb-2 diagram-parent">
                  <div className="col-sm-6 chart-parent doughnut-chart">
                    <canvas id="doughnut" ></canvas>
                  </div>
                  <div className="col-sm-6 chart-parent vertical-chart">
                    <canvas id="vertical-bar" ></canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Reports;
