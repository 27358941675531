import React, { useEffect, useState } from "react";
import axios from "axios";
import CustomPagination from "../../components/CustomPagination";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { formatDate, trimAddress, copyToClipboard } from "../../helper/helper";
import { IoCopyOutline } from "react-icons/io5";

const TransactionManagement = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const [activeTab, setActiveTab] = useState("apps");

  const fetchAppsTransaction = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/merchant-app-tx/tx-list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 10,
            search: searchQuery,
          },
        }
      );

      setTransactions(response?.data?.data);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchPaymentTransaction = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/payment-link/admin-all-payments`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 10,
            search: searchQuery,
          },
        }
      );

      setTransactions(response?.data?.data);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching payment transactions:", error);
    }
  };

  useEffect(() => {
    if (activeTab === "apps") {
      fetchAppsTransaction();
    } else if (activeTab === "payment") {
      fetchPaymentTransaction();
    }
  }, [currentPage, searchQuery, activeTab]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  console.log("transactions", transactions);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 d-flex justify-content-between">
              <div className="col-sm-6">
                <h1 className="m-0">Transaction Management</h1>
              </div>
              <div className="col-sm-3">
                <h4
                  className={`m-0 ${activeTab === "apps" ? "activee" : ""}`}
                  onClick={() => handleTabChange("apps")}
                  style={{ cursor: "pointer" }}
                >
                  Apps
                </h4>
              </div>
              <div className="col-sm-3">
                <h4
                  className={`m-0 ${activeTab === "payment" ? "activee" : ""}`}
                  onClick={() => handleTabChange("payment")}
                  style={{ cursor: "pointer" }}
                >
                  Payment
                </h4>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <table className="table">
                      <thead>
                        <tr>
                          <th>SN</th>
                          <th>Chain Id</th>
                          <th>Transaction Hash</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Time Stamp</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions?.length ? (
                          transactions.map((data, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data?.chainId}</td>
                              <td>
                                {trimAddress(data.hash, 10, 5)}

                                {data.hash ? (
                                  <button
                                    onClick={() => copyToClipboard(data.hash)}
                                    style={{
                                      marginLeft: "1rem",
                                      border: "none",
                                      background: "transparent",
                                    }}
                                  >
                                    <IoCopyOutline />
                                  </button>
                                ) : (
                                  "-------------"
                                )}
                              </td>
                              <td>
                                {trimAddress(data.fromAddress, 10, 5)}
                                {data.fromAddress ? (
                                  <button
                                    onClick={() =>
                                      copyToClipboard(data.fromAddress)
                                    }
                                    style={{
                                      marginLeft: "1rem",
                                      border: "none",
                                      background: "transparent",
                                    }}
                                  >
                                    <IoCopyOutline />
                                  </button>
                                ) : (
                                  "---------------"
                                )}
                              </td>
                              <td>
                                {trimAddress(data.toAddress ?? data.to, 10, 5)}
                                <button
                                  onClick={() =>
                                    copyToClipboard(data.toAddress ?? data.to)
                                  }
                                  style={{
                                    marginLeft: "1rem",
                                    border: "none",
                                    background: "transparent",
                                  }}
                                >
                                  <IoCopyOutline />
                                </button>
                              </td>

                              <td>
                                {data.recivedAmount ?? data.amount}{" "}
                                {data.symbol}
                              </td>
                              <td>{data.status}</td>
                              <td>{formatDate(data?.createdAt)}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  marginTop: "20px",
                                }}
                              >
                                <p>Data not found</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionManagement;
