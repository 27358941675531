import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddInquiry = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    platformName: "",
    platformCategory: "",
    location: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    Object.keys(formData).forEach((key) => {
      const value = formData[key];
      if (!value) {
        newErrors[key] = `${
          key.charAt(0).toUpperCase() + key.slice(1)
        } is required`;
      } else if (!/^\d{10}$/.test(formData.contactNumber)) {
        newErrors.contactNumber = "Contact number is invalid";
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = "Email is invalid";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      // Sending data without trimming
      await axios.post(
        `${process.env.REACT_APP_API_URL}/inquiry/add`,
        formData
      );
      toast.success("Inquiry added successfully!");
      navigate("/inquiry");
    } catch (error) {
      console.error("Error adding inquiry:", error);
      toast.error("Failed to add inquiry. Please try again.");
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Inquiry Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleSubmit}>
                    <div className="card-body">
                      <>
                        <div className="form-group">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            name="name"
                            className={`form-control ${
                              errors.name ? "is-invalid" : ""
                            }`}
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter name"
                          />
                          {errors.name && (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input
                            type="text"
                            name="email"
                            className={`form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter email"
                          />
                          {errors.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="contactNumber">Contact Number</label>
                          <input
                            type="text"
                            name="contactNumber"
                            className={`form-control ${
                              errors.contactNumber ? "is-invalid" : ""
                            }`}
                            value={formData.contactNumber}
                            onChange={handleChange}
                            placeholder="Enter contactNumber"
                          />
                          {errors.contactNumber && (
                            <div className="invalid-feedback">
                              {errors.contactNumber}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="email">Platform Name</label>
                          <input
                            type="text"
                            name="platformName"
                            className={`form-control ${
                              errors.platformName ? "is-invalid" : ""
                            }`}
                            value={formData.platformName}
                            onChange={handleChange}
                            placeholder="Enter platformName"
                          />
                          {errors.platformName && (
                            <div className="invalid-feedback">
                              {errors.platformName}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="platformCategory">
                            Platform Category
                          </label>
                          <input
                            type="text"
                            name="platformCategory"
                            className={`form-control ${
                              errors.platformCategory ? "is-invalid" : ""
                            }`}
                            value={formData.platformCategory}
                            onChange={handleChange}
                            placeholder="Enter platformCategory"
                          />
                          {errors.platformCategory && (
                            <div className="invalid-feedback">
                              {errors.platformCategory}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="location">Location</label>
                          <input
                            type="text"
                            name="location"
                            className={`form-control ${
                              errors.location ? "is-invalid" : ""
                            }`}
                            value={formData.location}
                            onChange={handleChange}
                            placeholder="Enter location"
                          />
                          {errors.location && (
                            <div className="invalid-feedback">
                              {errors.location}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="description">Description</label>
                          <textarea
                            name="description"
                            className={`form-control ${
                              errors.description ? "is-invalid" : ""
                            }`}
                            value={formData.description}
                            onChange={handleChange}
                            placeholder="Enter description"
                          />
                          {errors.description && (
                            <div className="invalid-feedback">
                              {errors.description}
                            </div>
                          )}
                        </div>
                      </>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInquiry;
