import React, { useEffect, useState } from "react";
import axios from "axios";
import CustomPagination from "../../components/CustomPagination";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/contact-us/list`,
        {
          params: {
            pageNo: currentPage,
            limitVal: 10,
            search: searchQuery,
          },
        }
      );

      setUsers(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage, searchQuery]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
    // setCurrentPage(totalPages);
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Contact Us</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <input
                      type="text"
                      placeholder="Search users..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Contact Number</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users?.length > 0 ? (
                          users.map((user, index) => (
                            <tr key={index}>
                              <td>{user?.name}</td>
                              <td>{user?.email}</td>
                              <td>{user?.contactNumber}</td>
                              <td>
                                {/* <Link
                                  to={`/contact-us-view/${user?._id}`}
                                  className="btn btn-link merch-view"
                                >
                                  <i className="fas fa-eye"></i>
                                </Link> */}
                                <Link
                                  to={`/contact-us-update/${user?._id}`}
                                  className="btn btn-link merch-view"
                                >
                                  <span className="merch-edit">
                                    <i className="fas fa-edit"></i>
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          //data not found
                          <tr>
                            <td colSpan="7">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  marginTop: "20px",
                                }}
                              >
                                <p>Data not found</p>
                              </div>
                            </td>
                          </tr>
                        )}

                      </tbody>
                    </table>
                  </div>
                </div>

                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
