import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const ForgotPassword = ({}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [wrongEmailMsg, setWrongEmailMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Please enter email.");
      return;
    }
    setError("");

    // Validate email format
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    setError("");

    setIsLoading(true);

    try {
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/admin/verify-mail`, {
          email: email,
        })
        .then((response) => {
          toast.success("Reset Password Link send at your mail id!");
          setEmail("");
          setWrongEmailMsg("");
        })
        .catch((error) => {
          console.error("Error:", error);

          setWrongEmailMsg(
            "Invalid email address. Please enter a valid email address"
          );
        });
    } catch (error) {
      console.log("catch error", error);
      setError("error");
    } finally {
      setIsLoading(false);
    }
  };

  console.log("wrongEmailMsg", wrongEmailMsg);

  return (
    <>
      <div className="login-box">
        <div className="login-logo">
          <b style={{ fontSize: "Larger", color: "white" }}>Forget Password</b>
        </div>

        <div className="card">
          <div className="card-body register-card-body">
            {error && <p className="text-danger">{error}</p>}
            <span className="text-danger">{wrongEmailMsg}</span>
            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block">
                    Submit
                  </button>
                </div>
              </div>
            </form>
            {isLoading && (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
