import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const WithdrawalLimit = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [limit, setLimit] = useState({});
  const [updatedLimit, setUpdatedLimit] = useState({});

  const fetchTokens = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/token/list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 12,
            search: searchQuery,
          },
        }
      );
      const tokens = response?.data?.data;
      setTokens(tokens);
      setCurrentPage(response?.data?.currentPage);
      setTotalPages(response?.data?.totalPages);

      const initialLimits = {};
      tokens.forEach((token) => {
        initialLimits[token?._id] = (1).toFixed(1); // Initialize each token's limit to 1.0
      });

      setUpdatedLimit(initialLimits); // Initialize updated limits to the same value
    } catch (error) {
      console.error("Error fetching tokens:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event, tokenId) => {
    const newValue = event.target.value;

    // Check if the input is a valid number and greater than or equal to 1
    if (!isNaN(newValue)) {
      setLimit((prevLimits) => ({
        ...prevLimits,
        [tokenId]: newValue, // Update the limit for the specific tokenId
      }));
    }
  };

  const handleSetLimit = async (tokenId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/token/update-min-withdraw`,
        {
          tokenId: tokenId,
          minWithdraw: limit[tokenId],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      //comment
      const limitValue = response?.data?.data?.minWithdraw;
      if (response.status === 201) {
        toast.success("Withdrawal Limit updated successfully!");
        setUpdatedLimit((prevLimits) => ({
          ...prevLimits,
          [tokenId]: limitValue, // Update only the current token's limit
        }));
        fetchTokens();
      } else {
        toast.error("Failed to update the Withdrawal Limit. Please try again.");
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message[0];
      console.error("Error updating Withdrawal Limit:", error.response);
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    fetchTokens();
  }, [currentPage, searchQuery]);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 d-flex justify-content-between">
              <div className="col-sm-6">
                <h1 className="m-0">Withdrawal Limit Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.N.</th>
                          <th>Coin Name</th>
                          <th>Blockchain Network</th>
                          <th>Code</th>
                          <th>Set Limit</th>
                          <th>Current Limit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tokens?.length ? (
                          tokens.map((value, key) => (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{value?.symbol}</td>
                              <td>{value?.network}</td>
                              <td>{value?.code}</td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-around",
                                    gap: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      name="transaction-fee"
                                      value={
                                        limit[value._id] ?? value.minWithdraw
                                      } // Use the limit if available, otherwise fallback to the original value
                                      className="form-control"
                                      // onChange={(event) =>
                                      //   handleInputChange(event, value?._id)
                                      // }

                                      onChange={(event) => {
                                        const inputValue = event.target.value;

                                        // Check if the input value is either an empty string (to allow clearing the field) or a positive number
                                        if (
                                          inputValue === "" ||
                                          (!isNaN(inputValue) &&
                                            Number(inputValue) >= 0)
                                        ) {
                                          handleInputChange(event, value?._id);
                                        }
                                      }}
                                      style={{ width: "100px" }}
                                    />

                                    <span>{value?.symbol}</span>
                                  </div>
                                  <div>
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => handleSetLimit(value?._id)}
                                      disabled={
                                        limit[value?._id] ? false : true
                                      }
                                    >
                                      Set
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span>{value.minWithdraw}</span>{" "}
                                {/* Display current limit or default 1.0 */}
                                <span style={{ marginLeft: "5px" }}>
                                  {value?.symbol}
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  marginTop: "20px",
                                }}
                              >
                                <p>Data not found</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <CustomPagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalLimit;
