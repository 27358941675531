import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { ValidateInputs } from "../../helper/helper";
import { isValidAddress } from "../../helper/evm.helper";

const FeeManagement = () => {
  const [formData, setFormData] = useState({
    platformFee: "",
    adminWallet: "",
  });
  const [errors, setErrors] = useState({});
  const [adminFeeInfo, setAdminFeeInfo] = useState(); // Current fee from the server

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    const updatedInputs = await ValidateInputs(name, value, errors);
    console.log("----RRRR : ", updatedInputs);

    setErrors(updatedInputs);
  };

  const fetchPlatformFee = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/get-platform-fee`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setAdminFeeInfo(response?.data?.data);
        setFormData(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching Platform Fee:", error);
    }
  };

  const handleSetFee = async (event) => {
    event.preventDefault(); // Prevent form submission from reloading the page

    // const isAddress = await isValidAddress(formData?.adminWallet);
    // console.log("isAddress is : ", isAddress);

    if (errors?.platformFee || errors?.adminWallet) {
      return;
    }

    // if (!isAddress) {
    //   toast.error(`Invalid admin wallet Address`);
    //   return;
    // }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/update-platform-fee`,
        {
          platformFee: formData.platformFee,
          adminWallet: formData.adminWallet,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Platform Fee updated successfully!");
      } else {
        toast.error("Failed to update the platform fee. Please try again.");
      }
    } catch (error) {
      console.error("Error updating Platform Fee:", error);
      toast.error("An error occurred while updating the Platform Fee.");
    }
  };

  useEffect(() => {
    fetchPlatformFee();
  }, []);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Platform Fee Update/Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="card card-primary"
                  style={{
                    maxWidth: "500px",
                  }}
                >
                  <form id="quickForm" onSubmit={handleSetFee}>
                    <div className="card-body">
                      <>
                        <div className="form-group">
                          <label htmlFor="platformFee">Platform Fee (%)</label>
                          <input
                            type="number"
                            name="platformFee"
                            className={`form-control ${
                              errors.platformFee ? "is-invalid" : ""
                            }`}
                            value={formData.platformFee}
                            onChange={handleChange}
                            placeholder="Enter platform fee in percent: 5%"
                          />
                          {errors.platformFee && (
                            <div className="invalid-feedback">
                              {errors.platformFee}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="adminWallet">
                            Admin Wallet Address
                          </label>
                          <input
                            type="text"
                            name="adminWallet"
                            className={`form-control ${
                              errors.adminWallet ? "is-invalid" : ""
                            }`}
                            value={formData.adminWallet}
                            onChange={handleChange}
                            placeholder="Enter admin wallet address"
                          />
                          {errors.adminWallet && (
                            <div className="invalid-feedback">
                              {errors.adminWallet}
                            </div>
                          )}
                        </div>
                      </>
                    </div>
                    <div
                      className="card-footer"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={adminFeeInfo === formData}
                      >
                        Set Fee & Address
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeManagement;
